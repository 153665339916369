import React from 'react'

const StayingAtLux = () => {
  return (
    <div>
        staying at lux
    </div>
  )
}

export default StayingAtLux