import React from 'react'


const WindsurfingInEgypt = () => {
  return (
    <div>
      windsurfing in egypt
    </div>
  )
}

export default WindsurfingInEgypt