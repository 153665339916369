import React from 'react'


const ThingsToDoInMauritius = () => {
  return (
    <div>
      things to do in mauritius
    </div>
  )
}


export default ThingsToDoInMauritius